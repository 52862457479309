<template>
  <div class="content">
    <div class="row">
     
      <div class="col-md-12">
        <vuestic-widget :headerText="header+': '+userktag.codektag">
          <div class="row">
            <div class="col-md-12">
              <button class="btn-button btn-primary btn-micro" @click="backPage"> <i class="fa fa-chevron-left"></i>  Volver a ktags</button>

            </div>
            </div>
          <div class="row">
            
            <div class="col-md-2">
               <div class="card">
                <div class="card-header headercard headerorange">
                  <label>
                    <strong>{{userktag.topup_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Recarga<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>

          <div class="col-md-2">
               <div class="card">
                <div class="card-header headercard headergreen">
                  <label>
                    <strong>- {{userktag.sales_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Ventas<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>
           <div class="col-md-2">
               <div class="card">
                <div class="card-header headercard headerorange">
                  <label>
                    <strong>- {{userktag.refund_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Reembolso<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>
          <div class="col-md-3">
               <div class="card">
                <div class="card-header headercard headergreen">
                  <label>
                    <strong>= {{userktag.server_balance}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Server  Balance<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>

           <!--  <div class="col-md-2">
               <div class="card">
                <div class="card-header headercard headerpink">
                  <label v-if="userktag.ktag_balance">
                    <strong>{{userktag.ktag_balance.virtual_credit}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Ktag Virtual Balance<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>-->

             <div class="col-md-3">
               <div class="card">
                <div class="card-header headercard headerpurple">
                  <label v-if="userktag.ktag_balance">
                    <strong>{{userktag.ktag_balance.standard_credit}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Ktag Balance<br></div>
                      
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
          <br>
          <p>El Ktag Balance representa el saldo capturado en las ultimas transacciones sincronizadas, no necesariamente coincide con el Server balance.</p>
          <div class="row">
            <div class="col-md-12">
                        <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Cantidad de Recargas</th>
                  <th scope="col">Recargas Standard</th>
                  <th scope="col">Recargas Virtuales</th>
                  <th scope="col">Cant. Ventas Concretadas</th>
                  <th scope="col">Cant. Ventas Devueltas</th>
                  <th scope="col"> Cantidad de Refunds</th>
                </tr>
              </thead>
              <tbody v-if='userktag'>
                <tr>                 
                  <td>{{userktag.count_topup_total}}</td>
                  <td>{{userktag.standard_topup_total}}</td>
                  <td>{{userktag.virtual_topup_total}}</td>
                  <td>{{userktag.standard_sales_count}}</td>
                  <td>{{userktag.standard_sales_fail_count}}</td>
                  <td>{{userktag.count_refund_total}}</td>
                </tr>
               
              </tbody>
            </table>
          </div>
          </div>
        </vuestic-widget>
        <vuestic-widget>
        <vuestic-tabs class="tabs" :names="['Recargas', 'Ventas','Reembolso','Historial de Conexión',]">
          <div slot="Recargas">
            <div class="row"><div class="col-md-12">
          
               <TableTopupKtag></TableTopupKtag>

               </div>
            </div>
              

          </div>
          <div slot="Ventas">
            <div class="row"><div class="col-md-12">
          
               <TableSalesKtag></TableSalesKtag>

               </div>
            </div>
         
          </div>
           <div slot="Reembolso">
              <div class="row"><div class="col-md-12">
          
               <TableRefundKtag></TableRefundKtag>

               </div>
            </div>
         
          </div>
            <div slot="Historial de Conexión">
              <div class="row"><div class="col-md-12">
          
               <TableConexionKtag></TableConexionKtag>

               </div>
            </div>
         
          </div>
        </vuestic-tabs>
        </vuestic-widget>
        </div>
        <!-- ONLY STATIONS EVENT MAGAMENET -->
         
      


       
    

    
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableTopupKtag from "../tables/TableKtag/TableTopupKtag.vue";
import TableSalesKtag from "../tables/TableKtag/TableSalesKtag.vue";
import TableRefundKtag from "../tables/TableKtag/TableRefundKtag.vue";
import TableConexionKtag from "../tables/TableKtag/TableConexionKtag.vue";

import Multiselect from "vue-multiselect";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";

// example of data
let chartData = {
  labels: ["January", "February"],
  datasets: [
    {
      label: "GitHub Commits",
      backgroundColor: "#f87979",
      data: [40, 20]
    }
  ]
};

export default {
  name: "AnalyticByStation",
  components: {
    SpringSpinner,
    TableTopupKtag,
    TableSalesKtag,
    TableRefundKtag,
    TableConexionKtag,
    Multiselect
  },
  mounted() {
    // checkbox and radios


  var idktag = this.$route.params.id;
    this.loadDetailKtag(idktag)
      .then(data => {
        this.userktag = data;
      
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      }); 
  },
  computed: {
    ...mapState({
      //  typestationlist: state => state.typestation.typestations,
      //userktag: state => state.userktag.userktag,
      idevent: state => state.event.event.id
    })
  },
  data() {
    return {
      header: "Detalle de Ktag Customer",
      server_standard_balance:null,
      userktag:{
      },
      
      
    };
  },
  methods: {
    ...mapActions([    
      "loadDetailKtag",    
      "storeStation",
      "addToastMessage"
    ]),
    backPage: function() {
      this.$router.go(-1);
    },
    saveStaff() {
      this.$validator.validate().then(result => {
        if (result) {
          this.storeStation({
            name: this.name,
            number: this.number,
            location: this.location,
            id_event: this.idevent,
            active: this.active,
            id_type_station: this.typestation.id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              setTimeout(() => this.$router.replace("/station"), 2000);
            })
            .catch(data => {
              this.error = data.message;
              this.addToastMessage({
                text: data.message,
                type: "warning"
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
.headercard {
  height: 100px;
  color: white;
  font-size: 25px;
  font-weight: 600;
}
.headergreen {
  background: rgba(3, 153, 136, 1);
  background: -moz-linear-gradient(
    left,
    rgba(3, 153, 136, 1) 0%,
    rgba(99, 252, 152, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(3, 153, 136, 1)),
    color-stop(100%, rgba(99, 252, 152, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(3, 153, 136, 1) 0%,
    rgba(99, 252, 152, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(3, 153, 136, 1) 0%,
    rgba(99, 252, 152, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(3, 153, 136, 1) 0%,
    rgba(99, 252, 152, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(3, 153, 136, 1) 0%,
    rgba(99, 252, 152, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#039988', endColorstr='#63fc98', GradientType=1 );
}
.headerorange {
  background: rgba(254, 82, 79, 1);
  background: -moz-linear-gradient(
    left,
    rgba(254, 82, 79, 1) 0%,
    rgba(249, 207, 37, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(254, 82, 79, 1)),
    color-stop(100%, rgba(249, 207, 37, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(254, 82, 79, 1) 0%,
    rgba(249, 207, 37, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(254, 82, 79, 1) 0%,
    rgba(249, 207, 37, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(254, 82, 79, 1) 0%,
    rgba(249, 207, 37, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(254, 82, 79, 1) 0%,
    rgba(249, 207, 37, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe524f', endColorstr='#f9cf25', GradientType=1 );
}
.headerpink {
  background: rgba(255, 3, 104, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 3, 104, 1) 0%,
    rgba(255, 149, 151, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 3, 104, 1)),
    color-stop(100%, rgba(255, 149, 151, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 3, 104, 1) 0%,
    rgba(255, 149, 151, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 3, 104, 1) 0%,
    rgba(255, 149, 151, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 3, 104, 1) 0%,
    rgba(255, 149, 151, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 3, 104, 1) 0%,
    rgba(255, 149, 151, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0368', endColorstr='#ff9597', GradientType=1 );
}
.headerpurple{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e570e7+0,c85ec7+47,a849a3+100;Pink+3D+%233 */
background: #e570e7; /* Old browsers */
background: -moz-linear-gradient(left, #e570e7 0%, #c85ec7 47%, #a849a3 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, #e570e7 0%,#c85ec7 47%,#a849a3 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #e570e7 0%,#c85ec7 47%,#a849a3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e570e7', endColorstr='#a849a3',GradientType=1 ); /* IE6-9 */
}
.labelanalytic {
  font-weight: 600;
  font-size: 15px;
}
</style>